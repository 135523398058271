import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import config from './amplifyconfiguration.json';
import { createVote } from "./graphql/mutations";

Amplify.configure(config);
const client = generateClient();

document.querySelectorAll(".vote").forEach((item) => {
  console.log("add listener");
  item.addEventListener("click", sendVote);
});

async function sendVote(e) {
  var votedFor = e.target.dataset.ad;

  document.getElementById("options").classList.remove("novote");
  document.getElementById("options").classList.add("done");
  document.getElementById("instructions").innerText = "Processing your vote...";

  try {

    gtag('event', votedFor);

    const newVote = await client.graphql({
      query: createVote,
      variables: {
        input: {
          vote: votedFor,
        }
      }
    });

    console.log(newVote);
    document.cookie =
      "vote24=" + votedFor + "; expires=Sun, 12 Feb 2024 23:59:59 GMT; Secure";
    document.getElementById(votedFor).classList.add("yes");
    document.getElementById("instructions").innerText = "Thanks for voting!";
  } catch (err) {
    console.error(err);
    document.cookie =
      "vote24=" + votedFor + "; expires=Sun, 12 Feb 2024 23:59:59 GMT; Secure";
    document.getElementById(votedFor).classList.add("yes");
    document.getElementById("instructions").innerText = "Thanks for voting!";

    /*
        setTimeout(() => {
        document.getElementById("options").classList.add("novote");
        document.getElementById("options").classList.remove("done");
        document.getElementById("instructions").innerText = "Vote for your favorite!";
        alert("Please try submitting your vote again");
        }, 320);
        */
  }
}

function init() {
  console.log("run init");
  
  //OPEN FOR VOTE
  if (document.cookie.split("; ").find((row) => row.startsWith("vote24="))) {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("vote24="))
      .split("=")[1];
    document.getElementById("options").classList.add("done");
    document.getElementById(cookieValue).classList.add("yes");
    document.getElementById("instructions").innerText = "Thanks for voting!";
  } else {
    document.getElementById("options").classList.add("novote");
    document.getElementById("instructions").innerText =
      "Vote for your favorite!";
  }
  //END OPEN VOTE
  
  document.getElementById("termsLink").onclick = function () {
    document.getElementById("terms").classList.toggle("show");
  };

  //VOTE CLOSED
  /*
    document.getElementById("options").classList.add("done");
    document.getElementById("coke").classList.add("yes");
    document.getElementById("instructions").innerText = "Coke is the 2023 Winner. Voting is closed. See you next year!";
  */
  
}

window.onload = function () {
  init();
};
